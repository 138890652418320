<template>
    <div class="modal is-active">
    <div class="modal-background" @click="close"></div>
    <div class="modal-content">
      <img :src="image" class="centered-image">
    </div>
    <button class="modal-close is-large" aria-label="close" @click="close"></button>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    image: {
      type: String
    }
  }
}
</script>
<style scoped>
.centered-image {
  display: block;
  margin: 0 auto;
}
</style>
